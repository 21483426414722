import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { materiales } from '@/utils/materiales'

export default function useMaterialesList() {
  // Use toast
  const toast = useToast()

  const refMaterialesRecepcionListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'num_pedido_proveedor', label: 'Nº de pedido', sortable: true },
    { key: 'fecha_recepcion', label: 'Fecha de Recepción', sortable: true },
    { key: 'fk_proveedor', label: 'Proveedor', sortable: true },
    { key: 'fk_user', label: 'Usuario', sortable: true },
    { key: 'actions', label: 'Acciones' },
  ]
  const perPage = ref(50)
  const totalMaterialesRecepcion = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refMaterialesRecepcionListTable.value ? refMaterialesRecepcionListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalMaterialesRecepcion.value,
    }
  })

  const refetchData = () => {
    refMaterialesRecepcionListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, statusFilter], () => {
    refetchData()
  })

  const fetchMaterialesRecepcion = (ctx, callback) => {
    store
      .dispatch('app-materiales-recepcion/fetchMaterialesRecepcion', {
        body: {
          search: searchQuery.value,
          paginate: perPage.value,
        },
        headers: {
          'X-Sort': `materiales_recepcions.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {
          page: currentPage.value,
        },
      })
      .then(res => {
        const { total } = res
        totalMaterialesRecepcion.value = total
        callback(res.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching materiales list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const listMateriales = () => new Promise((resolve, reject) => {
    store
      .dispatch('app-materiales-recepcion/listMaterialesRecepcion', {
        body: {
          search: searchQuery.value,
        },
        headers: {
          'X-Sort': `materiales_recepcions.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {},
      })
      .then(res => {
        const { total } = res
        totalMaterialesRecepcion.value = total
        resolve(res.data)
      })
      .catch(e => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching materiales list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        reject(e)
      })
  })

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchMaterialesRecepcion,
    tableColumns,
    perPage,
    currentPage,
    totalMaterialesRecepcion,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMaterialesRecepcionListTable,

    refetchData,
    listMateriales,
  }
}
